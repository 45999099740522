<template>
  <b-row>
    <b-col lg="12">
      <sales-report-card />
    </b-col>
  </b-row>
</template>

<script>
import SalesReportCard from "@core/components/statistics-cards/SalesReportCard.vue"

export default {
  components: {
    SalesReportCard,
  },
}
</script>
