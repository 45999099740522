<template>
  <div>
    <!--<Mapbox />-->
    <b-row class="match-height">
      <b-col lg="8">
        <establishment-admin-statistics />
      </b-col>
      <b-col lg="4" @click="toStablishment()">
        <statistic-card-vertical
          icon="ShoppingBagIcon"
          :statistic="Number(dashboardData.store_count) || 0"
          :statistic-title="`Punto${
            dashboardData.store_count > 1 ? 's' : ''
          } de venta`"
          color="primary"
          class="pt-1 cursor-pointer"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <sales-transactions-table />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex"

import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue"
import SalesTransactionsTable from "@/views/sales/SalesTransactionsTable.vue"
import EstablishmentAdminStatistics from "@/@core/components/EstablishmentAdminStatistics.vue"

export default {
  components: {
    StatisticCardVertical,
    SalesTransactionsTable,
    EstablishmentAdminStatistics,
    // Mapbox,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      dashboardData: {},
      fields: [
        {
          key: "#",
          label: "#",
        },
        {
          key: "created_at",
          label: "Fecha",
        },
        {
          key: "payment_type",
          label: "Tipo de pago",
        },
        {
          key: "iva",
          label: "IVA",
          class: "text-right",
        },
        {
          key: "subtotal",
          label: "Subtotal",
          class: "text-right",
        },
        {
          key: "total",
          label: "Total",
          class: "text-right",
        },
      ],
    }
  },
  created() {
    this.fetchDashboard().then((response) => {
      this.dashboardData = response
    })
    this.fetchOrders()
  },
  methods: {
    ...mapActions("dashboard", ["fetchDashboard"]),
    ...mapActions("orders", ["fetchOrders"]),
    ...mapMutations("orders", ["setOrders"]),
    toStablishment() {
      this.$router.push({
        name: "establishment-view",
        params: {
          id: this.userData.scoped_roles.filter(
            (x) => x.role_resource_type === "Establishment"
          )[0].role_resource_id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
